import React, { useState, useEffect } from "react";
import { Typography, Select, Button, message, Spin, Popconfirm, Drawer,Space,Avatar, List,Descriptions,Image,Collapse} from "antd";
import { getFirestore, doc, getDoc, setDoc, getDocs, query, collection, where, orderBy } from "firebase/firestore";
import isAdmin from "../../firebase/users/config";
import { catcher } from "../../firebase/catcher";
import { getAllUsers } from "../../firebase/users/get";
import { deleteUserById } from "../../firebase/users/delete";
import { beautifyKey } from "../../helpers/text";
import { splitName } from "../Users/SNAP/generateSnapPdf/get";

const { Title, Paragraph } = Typography;
const { Option } = Select;

const db = getFirestore();

export default function Config() {
  const [healthCenter, setHealthCenter] = useState([]);
  const [drawerOpen,setDrawerOpen] = useState(false)
  const [healthCenterCache, setHealthCenterCache] = useState([]);
  const [loading, setLoading] = useState(true);
  const [WIC_users,setWIC_users] = useState([])
  const [drawerLoading,setDrawerLoading] = useState(true)
  const [automationResponse,setAutomationResponse] = useState(false)

  const automateWIC_users = async() => {
    setAutomationResponse(true)
    let autoData = []
    WIC_users.forEach((user)=>{
      const newData = {
        "url": "https://www.mass.gov/forms/apply-for-wic-online",
        "errorsClass": "StyledErrorBanner-sc-lo45jw-0",
        "submitBtn": [
          "fsSubmitButton2949280"
        ],
        "id": user?.id,
        "hurdles": {
          "fsrButton__inviteDecline": "click"
        },
        "data": {
          "field60605056-first": splitName(user?.name?.value).first_name,
          "field60605056-last": splitName(user?.name?.value).last_name,
          "field60605057": user?.address?.value,
          "field60605058": "-",
          "field60605061": user?.zip?.value,
          "field61482470": user?.email?.value,
          "field61482470-confirmValue": "r@gmail.com",
          "field60605064": user?.phone?.value,
          "field60605059": user?.city?.value,
          "field60605060": "Massachusetts",
          "field61482341": user?.preferred_communication?.value        ,
          "field97012203_1": true,
          "Preferred Language": [
            user?.preferred_language?.value
          ],
          "bestTimeToContact": user?.best_time_to_contact?.value,
          "hearing": [
            "WIC office"
          ]
        },
        "decoders": {
          "Preferred Language": {
            "english": "field60605066_1",
            "spanish": "field60605066_2",
            "cantonese": "field60605066_3",
            "mandarin": "field60605066_4",
            "french": "field60605066_5",
            "portuguese": "field60605066_6",
            "russian": "field60605066_7",
            "arabic": "field60605066_8",
            "haitian creole": "field60605066_9",
            "other": "field60605066_10"
          },
          "bestTimeToContact": {
            "early morning": "field60605408_1",
            "morning": "field60605408_2",
            "afternoon": "field60605408_3",
            "late afternoon": "field60605408_4",
            "evening": "field60605408_5",
            "saturday morning": "field60605408_6"
          },
          "hearing": {
            "facebook or internet search": "field60605069_1",
            "wic office": "field60605069_2",
            "friend/family member": "field60605069_3",
            "advertisement (tv, radio, billboard)": "field60605069_4",
            "doctor's office/clinic": "field60605069_5",
            "dta office": "field60605069_6",
            "other": "field60605069_7"
          }
        }
      }
      autoData.push(newData)
    })
    const reqData = JSON.stringify({"data":autoData,"time":800})
    console.log(reqData)
    try {
      const res = await fetch(process.env.REACT_APP_AUTOMATE_URL, {
        method: 'POST',
        headers: {
          "Accept": "*/*",
          "Content-Type": "application/json"
         },
        body: reqData,
      });

      const result = await res.json();
      message.success("User's data will start automating in " + String(reqData.time) + " minutes")
    } catch (error) {
      console.error('Error:', error);
      message.error("Error: Unable to automate Data")
    } finally {
      setAutomationResponse(false);
    }
  }
  const transformDataToItems = (data) => {
    return Object.entries(data).map(([key, value]) => {
      let children;
      if (typeof value === 'object' && value !== null && value.hasOwnProperty('value')) {
        children = value.value;
      } else if (Array.isArray(value)) {
        children = value.join(', ');
      } else {
        if (value?.length > 70) {
          value = value?.substring(0, 70) + "...";
        }
        children = value;
      }
  
      return {
        key,
        label: beautifyKey(key),
        children,
      };
    });
  };

  useEffect(() => {
    if (drawerOpen){
      const fetchUsers = async () => {
        try {
          const q = query(collection(db, "User"), orderBy("mass_gov_wic_status", "asc"));
          const querySnapshot = await getDocs(q);
          const usersList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setWIC_users(usersList);
          console.log(usersList)
        } catch (error) {
          message.error("Error fetching users: ", error);
        } finally {
          setDrawerLoading(false);
        }
      };  
      fetchUsers();
    }
  }, [drawerOpen]);

  const showDrawer = () => {
    setDrawerOpen(true);
  };
  const onDraweClose = () => {
    setDrawerOpen(false);
  };

  const UserCard = ({ item }) => {
    // return (<p>s</p>)
    // console.log(typeof(item.id),item?.mass_gov_wic_status?.value)
    console.log(typeof(item.id) == "string" ? item.id : item.id?.value)
    return (
      <>
        <p>
          <b>ID: </b>{typeof(item.id) == "string" ? item.id : item.id?.value}<br/>
          <b>Status: </b>{item?.mass_gov_wic_status?.value}
        </p>
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configRef = doc(db, "config", "ambassadors");
        const docSnap = await getDoc(configRef);

        if (docSnap.exists()) {
          const healthCenters = docSnap.data().names || [];
          setHealthCenter(healthCenters);
          setHealthCenterCache(healthCenters);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        message.error("Failed to load Community Health Center names");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (!isAdmin()) {
    return <div style={{ padding: 20, textAlign: "center", color: "red" }}>You shouldn't be here!</div>;
  }

  const handleUpdate = async () => {
    try {
      const configRef = doc(db, "config", "ambassadors");
      await setDoc(configRef, { names: healthCenter });
      setHealthCenterCache({ ...healthCenter });
      message.success("Community Health Center names updated successfully");
    } catch (error) {
      console.error("Error updating document: ", error);
      message.error("Failed to update Community Health Center names");
    }
  };

  const isEdited = JSON.stringify(healthCenter) !== JSON.stringify(healthCenterCache);

  const handleCancel = () => {
    setHealthCenter(JSON.parse(JSON.stringify(healthCenterCache)));
  };

  const boxStyles = { maxWidth: 700, margin: "0 auto", background: "snow", padding: 30, border: "1px solid darkblue" };

  const handleDeleteEmptyUsers = async () => {
    await catcher(async () => {
      message.loading({ content: "Loading users...", key: "delete" });

      const users = await getAllUsers();
      const totalUsers = users.length;

      for (let i = 0; i < totalUsers; i++) {
        const user = users[i];
        if (String(user?.name).trim() === "" || !user?.name) {
          await deleteUserById(user?.id);
          message.loading({ content: `Deleting ${i + 1}/${totalUsers} users...`, key: "delete" });
        }
      }

      message.success({ content: "Finished deleting users", key: "delete", duration: 2 });
    }, {});
  };
  return (
    <div style={{ padding: 20 }}>
      <Title level={2}>App Config (Admin Only)</Title>
      <div style={boxStyles}>
        <Title level={4}>Community Health Center Names</Title>
        <Paragraph>Enter the names of Community Health Centers separated by commas.</Paragraph>
        {loading ? (
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <Spin />
          </div>
        ) : (
          <Select mode="tags" style={{ width: "100%" }} placeholder="Enter names separated by commas" tokenSeparators={[","]} onChange={setHealthCenter} value={healthCenter}>
            {healthCenter.map((name, index) => (
              <Option key={index} value={name}>
                {name}
              </Option>
            ))}
          </Select>
        )}
        <div style={{ marginTop: 20, width: "100%", justifyContent: "flex-end", display: "flex", gap: 20 }}>
          {isEdited && (
            <Button type="primary" style={{ background: "whitesmoke", color: "black" }} onClick={handleCancel}>
              Cancel
            </Button>
          )}
          <Button disabled={!isEdited} type="primary" onClick={handleUpdate}>
            Update
          </Button>
        </div>
      </div>
      <div style={boxStyles}>
        <Title level={4}>Other settings</Title>
        <Space>
          <Button type="primary" onClick={showDrawer}>
              User WIC status 
            </Button>
        <Popconfirm title="Confirm delte empty users?" description="It will delete users that has no name." onConfirm={handleDeleteEmptyUsers}>
          <Button type="primary" danger>
            Delete empty users
          </Button>
        </Popconfirm>
        </Space>
        <Drawer width={"70%"} title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>WIC User's List</span>
            <Button style={{ marginLeft: 'auto' }} loading={automationResponse} onClick={automateWIC_users}>Automate All</Button>
          </div>} onClose={onDraweClose} open={drawerOpen} loading={drawerLoading}>
        <List
          itemLayout="horizontal"
          dataSource={WIC_users}
          renderItem={(item, index) => (
            
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.gender? (item.gender == "M" ? 0 : 1):10}`} />}
                title={item?.name?.value}
                description={<><UserCard item={item}/><Collapse bordered={false} items={[
                  {
                    key: '1',
                    label: 'More Info',
                    children: <Descriptions items={transformDataToItems(item)} />,
                  },
                  {
                    key: '2',
                    label:'Automation Status',
                    children: <Collapse items={[
                      {
                        key: '1',
                        label: 'Full Page',
                        children: <Image fallback="https://placehold.co/300x300/EEE/31343C?text=404" src={"https://testing-wasilislam.pythonanywhere.com/automate/ahd/getFile?route=" + item?.id + "/" + item.id + "_fullPage.png"}/> , 
                      },
                      {
                        key: '2',
                        label: 'Error Page',
                        children: <Image fallback="https://placehold.co/300x300/EEE/31343C?text=404" src={"https://testing-wasilislam.pythonanywhere.com/automate/ahd/getFile?route=" + item?.id + "/" + item.id + "_error.png"}/> , 
                      },
                      {
                        key: '3',
                        label: 'Submitted',
                        children: <Image fallback="https://placehold.co/300x300/EEE/31343C?text=404" src={"https://testing-wasilislam.pythonanywhere.com/automate/ahd/getFile?route=" + item?.id + "/" + item.id + "_submitted.png"}/> , 
                      },
                    ]}>
                    </Collapse>
                  }
                ]} ></Collapse></>}
              />
            </List.Item>
          )}
        />
      </Drawer>
      </div>
    </div>
  );
}
