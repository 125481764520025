import { Alert, Button, Empty, Modal, Space, Tabs, Tag, Tooltip, Typography, message, notification } from "antd";
import axios from "axios";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import React, { useContext, useEffect, useState } from "react";
import { AiFillCheckCircle, AiFillCiCircle, AiFillHome } from "react-icons/ai";
import { CiMemoPad, CiPen } from "react-icons/ci";
import { FaHouse, FaSuitcase } from "react-icons/fa6";
import { TiInputCheckedOutline, TiTickOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import ObjectDisplayer from "../../components/ObjectEditor/ObjectEditor";
import useSize from "../../components/use/useSize.js";
import { catcher } from "../../firebase/catcher";
import isAdmin, { isSandbox } from "../../firebase/users/config";
import { getUserById } from "../../firebase/users/get";
import { createUser, updateUser } from "../../firebase/users/set";
import { getId } from "../../helpers/id";
import { getAllParamsFromLocation } from "../../helpers/loc";
import { cleanObject, wrapValueInObject } from "../../helpers/obj";
import ExternalScript from "../ExternalSite/index.js";
import MultiInputModal from "./MutliInputModal.js";
import SNAPPDF from "./SNAP/PDF/SNAPPDF";
import { fillPdfForm } from "./SNAP/PDF/generateSNAPPDF";
import { syncData } from "./SNAP/config/document.config.js";
import { fullUserObj } from "./assets/fullUserObj";
import { FormModal } from "./modals/Form.js";
import UserSignature from "./modals/UserSignature";
import "./styles.css";
import { BsArrowRight, BsDash, BsInputCursor } from "react-icons/bs";
import { BiIdCard } from "react-icons/bi";
import { MdArrowOutward } from "react-icons/md";
import { GiButterToast } from "react-icons/gi";
import { CgPlayButton } from "react-icons/cg";
import MainContext, { mainContextConfig } from "../../contexts/mainContext.js";
import { normalizeDate } from "../../helpers/time.js";
import ConsentForm from "../consents/DataPrivacyConsent.js";
import moment from "moment";
import TimelineLogs from "./Logs/Timeline.js";
import ScreenerForm from "../../components/Screener/Screener.js";
const { Title } = Typography;
const { TabPane } = Tabs;

const uploadPdfToFirebase = async (blob, path) => {
  const storage = getStorage();
  const pdfRef = storageRef(storage, `snap_pdfs/${path}.pdf`); // Customize the path as needed

  try {
    await uploadBytes(pdfRef, blob);
    // message.success('Uploaded PDF blob!',2);
  } catch (error) {
    // message.error("Error uploading PDF",2);
    console.log("Error uploading PDF blob:", error);
    return null;
  }
};

export default function User() {
  const { id } = getAllParamsFromLocation();
  const [faxSending, setFaxSending] = useState(false);
  const [modals, setModals] = useState({ extract_liheap: false });
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useSize();
  const { isExtraSmall, isSmall } = useSize();
  const [data, setData] = useState({
    ...fullUserObj,
  });
  const { loc } = useContext(MainContext);
  const locConfig = mainContextConfig["loc"][loc];

  const fetchUserData = async () => {
    const user = await getUserById(id);
    if (!user) {
      setErr("no-user");
    } else {
      const dateOfBirthValue = normalizeDate(user?.date_of_birth ?? user.date_of_birth?.value, false);
      setData((prevData) => ({
        ...prevData,
        ...user,
        date_of_birth: dateOfBirthValue ? { value: dateOfBirthValue } : user.date_of_birth,
      }));
    }
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    catcher(
      async () => {
        await fetchUserData();
      },
      { loading_msg: "Loading user information...", setLoading }
    );
  }, [id]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        catcher(
          async () => {
            await fetchUserData();
          },
          { loading_msg: "Refreshing user data..." }
        );
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleNewUser = () => {
    catcher(async () => {
      const id = await createUser({ loc, createdOn: getId("date") });
      navigate("/user?id=" + id);
    });
  };
  let userOptions = [
    // {
    //   icon: <FaChartBar />,
    //   disabled: data.partial_liheap,
    //   title0: "Liheap Application",
    //   title: "Upload LIHEAP Application",
    //   onClick: () => {
    //     setModals({ ...modals, extract_liheap: true });
    //   },
    //   done: data.liheap_uploaded,
    // },

    {
      icon: <BiIdCard />,
      title0: "Upload Documentation",
      title: "Extract data from ID/ Fill other applications",
      onClick: () => {
        setModals({ ...modals, multi_input_modal: true });
      },
      done: data.multi_input_modal,
    },
    {
      icon: <FaSuitcase />,
      title0: "Lifeline Eligibility",
      removeIf: "tx" === loc,
      title: "Verify patient's eligibility",
      onClick: () => {
        setModals({ ...modals, lifeline: true });
      },
      done: data.lifeline_uploaded,
    },
    {
      icon: <FaSuitcase />,
      title0: "WIC Application",
      removeIf: "tx" === loc || !isMobile,
      title: "Start a wic application",
      onClick: () => {
        setModals({ ...modals, wic: true });
      },
      done: data.mass_gov_wic_uploaded,
    },
    // {
    //   icon: <CiMemoPad />,
    //   title0: "SNAP Application",
    //   disabled: data.snap_full_uploaded,
    //   title: <>Add SNAP questions to LIHEAP</>,
    //   onClick: () => {
    //     setModals({ ...modals, snap_data: true });
    //   },
    //   done: data.snap_uploaded,
    // },
    {
      icon: <CiMemoPad />,
      title0: "Snap Application",
      disabled: data.snap_uploaded,
      id: "snap_box",
      removeIf: "tx" === loc,
      title: <>Start a new SNAP Enrollment</>,
      onClick: () => {
        if (!data?.signature)
          return notification.info({
            message: "Signature missing.",
            description: "User signature is required for SNAP. Please add the signature first, by clicking the signature box",
            placement: "top",
          });
        setModals({ ...modals, snap_data_not_liheap: true });
      },
      done: data.snap_full_uploaded,
    },
    {
      icon: <CiPen />,
      title0: "Signature",
      removeIf: "tx" === loc,
      title: <>Ask for patient's signature</>,
      onClick: () => {
        setModals({ ...modals, signature: true });
      },
      done: data.signature,
    },
    // { gap: true },
    // {
    //   icon: <CiMemoPad />,
    //   disabled: data.liheap_uploaded, // disable this option if liheap is uploaded
    //   title0: "Partial Liheap Application",
    //   title: <>Complete this if patient cannot stay</>,
    //   onClick: () => {
    //     setModals({ ...modals, partial_liheap: true });
    //   },
    //   done: data.partial_liheap,
    //   more_tags: [
    //     { title: "Email sent", show: data.partial_liheap_email_sent },
    //   ],
    // },
  ].filter((x) => (x.removeIf ? false : true));
  const displayCompleted = () => {
    notification.open({
      key: 123,
      message: "Application Completed",
      description: (
        <>
          Application has been completed!{" "}
          <Button
            size="small"
            style={{
              marginTop: 16,
              background: "green",
              borderColor: "darkgreen",
              color: "#fff",
            }}
            onClick={() => {
              handleBack(-1);
              notification.destroy(123);
            }}
          >
            <AiFillHome />
            Go back to dashboard
          </Button>
        </>
      ),
      icon: <AiFillCiCircle style={{ color: "green" }} />,
      duration: 0, // Duration time, in seconds, 0 for indefinitely
      style: {
        width: "1000px",
        marginLeft: `calc(50% - 330px)`, // Centering the notification
      },
      placement: "top",
    });
  };
  const handleUpdatePartialLiheap = (data) => {
    catcher(async () => {
      //send email to boston about the partial liheap
      if (!isSandbox())
        await axios.post(`${process.env.REACT_APP_BE}send-email`, {
          application_url: window.location.href,
          type: "partial_liheap",
          user_data: data,
        });
      await handleUpdateUser({
        ...data,
        partial_liheap: true,
        partial_liheap_email_sent: true,
      });
      displayCompleted();
    });
  };
  const handleSaveSignature = async (base64) => {
    catcher(
      async () => {
        const newdata = { ...data, signature: base64 };
        setData(newdata);
        await updateUser(id, newdata, loc);
        if (!window.location.href.includes("localhost")) {
          // sending email to meliissa
          if (!isSandbox() && false)
            await axios.post(`${process.env.REACT_APP_BE}send-email`, {
              application_url: window.location.href,
              type: "snap",
              user_data: data,
            });
        }
        // SNAP PDF WORK
        if (newdata["snap_uploaded"] || newdata["snap_full_uploaded"]) {
          message.info("Saving SNAP PDF. Sending FAX.");

          await saveSnapPdf(newdata);
          displayCompleted();
        }
        setModals({ ...modals, signature: false });
        // message.info("signature saved");
      },
      { loading_msg: "saving signature" }
    );
  };
  const handleUpdateUser = async (updates, displayUpdateMsg = true) => {
    try {
      // sync things eg date and age
      let updatesWrapped = syncData({
        ...wrapValueInObject(cleanObject(updates)),
      });
      const fullData = {
        ...data,
        ...updatesWrapped,
      };

      setData(fullData);

      console.log("updates2", updatesWrapped);

      await updateUser(id, updatesWrapped, loc);
      console.log(fullData);

      if (fullData?.["signature"] && !isSandbox()) {
        await saveSnapPdf(fullData);
      }
      if (displayUpdateMsg) message.info("User updated successfully");
      setModals({});
    } catch (error) {
      if (displayUpdateMsg) message.error("An error occurred while updating the user");
      console.error(error);
      setModals({});
    }
  };

  const sendSnapFax = async (pdfBlob) => {
    return true; // fax is off right now
    if (isSandbox()) return message.info("fax not sent due to sandbox account");
    const formData = new FormData();
    formData.append("file", pdfBlob, "file.pdf");

    axios
      .post(`${process.env.REACT_APP_BE}send_fax`, formData)
      .then((response) => message.info(`FAX Sent for SNAP PDF of user id: ${id} !`))
      .catch((error) => console.error("Error:", error));
  };
  const saveSnapPdf = async (data) => {
    const pdfBlob = await fillPdfForm(data);
    sendSnapFax(pdfBlob);
    await uploadPdfToFirebase(pdfBlob, data["id"]);
  };
  console.log("FULL DATA: ", data);

  const handleBack = (ask) => {
    if (ask === -1) return navigate("/");
    if (window.confirm("Do you want to go back to home?")) navigate("/");
  };

  if (!id) return "ID not present!";
  if (loading) return <Loading />;
  if (err === "no-user") return <Empty description="no user found" />;
  const tabItems = [
    {
      label: <Title level={4}>Data</Title>,
      key: "user_data",
      children: (
        <div className="user-data">
          <ObjectDisplayer obj={data} onUpdate={setData} />
        </div>
      ),
    },
    // {
    //   label: <Title level={4}>Logs</Title>,
    //   key: "2",
    //   children: (
    //     <div className="user-logs">
    //       <Title level={3}>Logs</Title>
    //       {JSON.stringify(data)}
    //       <LogDisplay logs={data.logs} />
    //     </div>
    //   ),
    // },
    // {
    //   label: <Title level={4}>Texas Lifeline</Title>,
    //   key: "texas_lifeline",
    //   children: (
    //     <ExternalScript
    //       form="texas_lifeline"
    //       updateUser={handleUpdateUser}
    //       id={id}
    //       user={data}
    //     />
    //   ),
    // },
    // {
    //   label: <Title level={4}>DTA Connect</Title>,
    //   key: "dta_connect",
    //   children: (
    //     <ExternalScript
    //       form="dta_connect"
    //       updateUser={handleUpdateUser}
    //       id={id}
    //       user={data}
    //     />
    //   ),
    // },
    {
      label: <Title level={4}>PDFs</Title>,
      key: "pdfs",
      removeIf: loc === "tx",
      children: (
        <Tabs defaultActiveKey="1" tabPosition="left" type="line">
          <TabPane tab="SNAP Pdf" key="1">
            <SNAPPDF
              user={data}
              openSnapForm={() => {
                const snap_box = userOptions.find((option) => option.id === "snap_box");
                snap_box.onClick();
              }}
            />
          </TabPane>
          {/* Add more tabs here as needed */}
        </Tabs>
      ),
    },
    {
      label: <Title level={4}>Logs</Title>,
      key: "Logs",
      // removeIf: loc === "tx",
      children: (
        <>
          <TimelineLogs user={data} />
        </>
      ),
    },
  ].filter((x) => (x?.removeIf ? false : true));

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]); // Get only the base64 part
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const sendFax = async () => {
    setFaxSending(true);
    try {
      const pdfBlob = await fillPdfForm(data);
      const base64File = await blobToBase64(pdfBlob);

      // Create JSON payload
      const payload = {
        to: "+16178878765", // Replace with actual recipient
        file: base64File,
      };

      // Send JSON payload to the API
      const apiResponse = await fetch("https://testing-wasilislam.pythonanywhere.com/fax/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (apiResponse.ok) {
        const result = await apiResponse.json();
        console.log("File sent successfully:", result);
        if (result.success) {
          await updateUser(data?.id, { snap_full_faxed: { value: false, processing: true, id: result?.data?.id } }, loc);
          message.success("Fax started... Please wait 15 minutes.");
        } else {
          message.error("Error starting Fax");
        }
      } else {
        message.error("Error sending file:", await apiResponse.json());
      }
    } catch (error) {
      message.error("Error fetching or sending file:", error);
    } finally {
      setFaxSending(false);
    }
  };

  if (!data?.dataPrivacyConsent && !data.name) {
    return (
      <div>
        <Typography.Title level={4} style={{ padding: 10 }}>
          Please sign this consent form to continue.
        </Typography.Title>
        <div className="container">
          <div className="step">
            <h2 className="step-title">Step 1: Screener</h2>
            <ScreenerForm user={data} handleUpdate={handleUpdateUser} />
          </div>
          <div className="step">
            <h2 className="step-title">Step 2: Consent</h2>

            <ConsentForm user={data} handleUpdate={handleUpdateUser} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="user-body">
      <Space>
        <Button onClick={handleBack}>
          <FaHouse />
          {"  "}
        </Button>

        {isAdmin() && data?.snap_full_uploaded?.value ? (
          data?.snap_full_faxed ? (
            data?.snap_full_faxed?.value === "processing" ? (
              <Button loading={faxSending}>Processing Fax...</Button>
            ) : (
              ""
            )
          ) : (
            <Button loading={faxSending} onClick={sendFax}>
              Send Fax
            </Button>
          )
        ) : (
          ""
        )}
      </Space>
      {false && (
        <Alert
          type="success"
          c="Application Completed"
          showIcon
          message={
            <>
              Application has been completed!{" "}
              <Button style={{ background: "darkgreen" }} type="primary" onClick={() => handleBack(-1)}>
                Fill another application
              </Button>
            </>
          }
        />
      )}
      <div>
        {/* <ExcelDownloader data={data}/> */}
        <h3>Steps to follow</h3>
        <div className={`user-options ${isExtraSmall && "mobile"}`}>
          {userOptions.map((option, index) => {
            // if (option.gap) {
            //   return (
            //     <div
            //       style={{
            //         width: "4px",
            //         background: "whitesmoke",
            //       }}
            //     ></div>
            //   );
            // }
            return (
              <>
                {index > 0 && !isMobile && (
                  <div style={{ height: 100 }}>
                    <BsDash style={{ visibility: "hidden" }} fontSize={"20px"} />
                  </div>
                )}
                <div
                  id={option.id ?? index + "rndm"}
                  style={{
                    border: option.done ? "2px solid green" : "", // Applies a green border if option.done is true
                    borderRadius: "5px", // Optional: adds rounded corners
                  }}
                  className={`user-option ${option.done && "done"} ${option.disabled && "disabled"}`}
                  {...option}
                  onClick={() => {
                    if (option.disabled) return message.info("An alternating option has been selected so this option is unselectable now.");
                    option.onClick();
                  }}
                >
                  <div className="icon">
                    <div>{option.icon}</div>
                  </div>
                  <Title level={5} className="title0">
                    {option.title0}
                  </Title>
                  <Title level={4}>{option.title}</Title>
                  {option.done && (
                    <Tag
                      color="green"
                      style={{
                        width: "fit-content",
                        position: "absolute",
                        top: 5,
                        right: 0,
                        fontSize: 16,
                      }}
                      className="flex1"
                    >
                      <AiFillCheckCircle /> done
                    </Tag>
                  )}
                  {option?.more_tags?.filter((tag) => tag.show)?.length ? (
                    <div>
                      More info:
                      <ol>
                        {option.more_tags.map((tag) => {
                          return <li> {tag.title}</li>;
                        })}
                      </ol>
                    </div>
                  ) : null}
                </div>
              </>
            );
          })}
        </div>
        <Tabs defaultActiveKey="1" type="line" items={tabItems} />
      </div>
      <div className="Modals">
        {/* <ExtractLiheap
          open={modals.extract_liheap}
          setOpen={(extract_liheap) => setModals({ ...modals, extract_liheap })}
          updateUser={(data) =>
            handleUpdateUser({ ...data, liheap_uploaded: true })
          }
        /> */}
        <MultiInputModal
          open={modals.multi_input_modal}
          setOpen={(multi_input_modal) => setModals({ ...modals, multi_input_modal })}
          user={data}
          id={id}
          updateUser={(data) => handleUpdateUser({ ...data, multi_input_modal: true })}
        />

        <UserSignature open={modals.signature} setOpen={(signature) => setModals({ ...modals, signature })} saveSignature={handleSaveSignature} />
        <FormModal
          user={data}
          open={modals.snap_data}
          setOpen={(snap_data) => setModals({ ...modals, snap_data })}
          updateUser={(data) => handleUpdateUser({ ...data, snap_uploaded: true })}
          type={"snap"}
        />
        <FormModal
          user={data}
          open={modals.snap_data_not_liheap}
          setOpen={(snap_data_not_liheap) => setModals({ ...modals, snap_data_not_liheap })}
          updateUser={(data) => handleUpdateUser({ ...data, snap_full_uploaded: true })}
          type="fullsnap"
        />
        <FormModal
          user={data}
          open={modals.lifeline}
          setOpen={(lifeline) => setModals({ ...modals, lifeline })}
          updateUser={(data) => handleUpdateUser({ ...data, lifeline_uploaded: true })}
          type="lifeline"
        />
        <FormModal
          user={data}
          open={modals.wic}
          setOpen={(wic) => setModals({ ...modals, wic })}
          updateUser={(data) => handleUpdateUser({ ...data, mass_gov_wic_uploaded: true, mass_gov_wic_status: "partial" })}
          type="wic"
        />
        <FormModal user={data} open={modals.partial_liheap} setOpen={(partial_liheap) => setModals({ ...modals, partial_liheap })} updateUser={handleUpdatePartialLiheap} type="partial_liheap" />
      </div>
      {/**Other consent forms */}
      <Modal
        width={"60%"}
        title="Signed Consent Form"
        open={modals.consent_form}
        onCancel={() => setModals({})}
        onOk={() => {
          setModals({});
        }}
      >
        <ConsentForm signed={true} user_data={data} />
      </Modal>
    </div>
  );
}
