import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Card, List, Typography } from "antd";
import "./ScreenerForm.css";
import MainContext from "../../contexts/mainContext";

const { Text } = Typography;

// Define the questions for MA and TX forms
const maQuestions = [
  { id: "massHealth", text: "Do you have MassHealth?" },
  { id: "childrenUnder5", text: "Do you have children under 5?" },
  { id: "childrenUnder19", text: "Do you have children under 19?" },
  { id: "socialSecurityNumber", text: "Do you have a SSN?" },
];

const txQuestions = [
  { id: "ssn", text: "Do you have a SSN?" },
  { id: "medicaid", text: "Do you have Medicaid?" },
  { id: "childrenUnder5", text: "Do you have children under 5 years old?" },
];

const ScreenerForm = ({ user, handleUpdate }) => {
  const { loc } = useContext(MainContext); // Context to determine location
  const [formState, setFormState] = useState({});
  const [results, setResults] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // Detect which form to use based on location (ma or tx)
    if (loc === "ma") {
      setQuestions(maQuestions);
      setFormState({
        massHealth: false,
        childrenUnder5: false,
        childrenUnder19: false,
        socialSecurityNumber: false,
      });
    } else if (loc === "tx") {
      setQuestions(txQuestions);
      setFormState({
        ssn: false,
        medicaid: false,
        childrenUnder5: false,
      });
    }
  }, [loc]);

  useEffect(() => {
    const updatedResults = Object.fromEntries(Object.entries(results).map(([key, value]) => [`screener_result_${key}`, value]));
    const updatedStates = Object.fromEntries(Object.entries(formState).map(([key, value]) => [`screener_state_${key}`, value]));

    handleUpdate({ ...updatedStates, ...updatedResults }, false);
  }, [results, formState]);

  const handleToggle = (id) => {
    setFormState((prevState) => {
      const newState = { ...prevState, [id]: !prevState[id] };
      updateResults(newState);
      return newState;
    });
  };

  const updateResults = (newState) => {
    const { massHealth, childrenUnder5, childrenUnder19, socialSecurityNumber, ssn, medicaid } = newState;
    let newResults = {
      wic: false,
      lifeline: false,
      eitc: false,
      getYourRefund: false,
      noMatch: false,
    };

    // MA-specific logic
    if (loc === "ma") {
      if (massHealth && childrenUnder19 && socialSecurityNumber) {
        newResults.tafdc = true;
      }
      if (massHealth && socialSecurityNumber) {
        newResults.lifeline = true;
      }
      if (massHealth && childrenUnder5) {
        newResults.wic = true;
      }
      if (massHealth && !socialSecurityNumber && !childrenUnder5 && !childrenUnder19) {
        newResults.noMatch = true;
      }
    }

    // TX-specific logic based on provided rules
    if (loc === "tx") {
      if (!ssn) {
        newResults.noMatch = "An SSN is required.";
      } else if (medicaid && childrenUnder5) {
        newResults.wic = true;
        newResults.lifeline = true;
        newResults.eitc = true;
        newResults.getYourRefund = true;
      } else if (medicaid) {
        newResults.lifeline = true;
        newResults.eitc = true;
        newResults.getYourRefund = true;
      } else if (childrenUnder5) {
        newResults.wic = true;
      } else {
        newResults.noMatch = true;
      }
    }

    setResults(newResults);
  };

  const resultItems = [
    results.tafdc && { title: "TAFDC", description: "Up to $447/month", color: "darkgreen" },
    results.lifeline && {
      title: "Lifeline",
      description: (
        <b style={{ textAlign: "right" }}>
          <p style={{ textAlign: "right", lineHeight: "0px" }}>Up to $111/year</p>
          <small style={{ fontWeight: "400" }}>for internet/phone subsidies</small>
        </b>
      ),
      color: "darkgreen",
    },
    results.wic && { title: "WIC", description: "$50-80/month", color: "darkgreen" },
    results.eitc && { title: "EITC", description: "Earned Income Tax Credit Program", color: "darkgreen" },
    results.getYourRefund && { title: "Get Your Refund", description: "Tax assistance program", color: "darkgreen" },
    results.noMatch && { title: "No Match Found", description: results.noMatch === true ? "No specific programs match patient's eligibility." : results.noMatch, color: "#F44336" },
  ].filter(Boolean);

  return (
    <Card className="form-container">
      <div className="checkbox-container">
        {questions.map((question) => (
          <div key={question.id} className="checkbox-field">
            <Checkbox checked={formState[question.id]} onChange={() => handleToggle(question.id)} style={{ fontSize: "18px", fontWeight: "bold" }}>
              {question.text}
            </Checkbox>
          </div>
        ))}
      </div>
      <div className="results-container">
        <List
          dataSource={resultItems}
          renderItem={(item) => (
            <List.Item className="result-item">
              <Text strong style={{ fontSize: "18px", color: item.color }}>
                {item.title}:
              </Text>
              <Text style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>{item.description}</Text>
            </List.Item>
          )}
        />
      </div>
    </Card>
  );
};

export default ScreenerForm;
