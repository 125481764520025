import React from "react";
import { Card, Statistic, Tag, Typography, Divider } from "antd";
import "./summary.css";
import isAdmin from "../../firebase/users/config";
import { applicationConfigs } from "../Home/home.config";
import ChartsSummary from "./ChartsSummary";
const processData = (data) => {
  return data.reduce(
    (acc, item) => {
      acc.totalApplications += 1;
      Object.values(applicationConfigs).forEach((config) => {
        if (item[config.uploaded_key]) {
          acc[config.summaryName] = (acc[config.summaryName] || 0) + 1;
        }
      });
      if (item.gender === "M") acc.maleApplications += 1;
      if (item.gender === "F") acc.femaleApplications += 1;
      // if (item.snap_full_uploaded || item.snap_uploaded) acc.snapApplications += 1;
      acc.instrumentType[item.application_type] = (acc.instrumentType[item.application_type] || 0) + 1;
      return acc;
    },
    {
      totalApplications: 0,
      femaleApplications: 0,
      maleApplications: 0,
      instrumentType: {},
      ...Object.values(applicationConfigs).reduce((acc, config) => {
        acc[config.summaryName] = 0;
        return acc;
      }, {}),
    }
  );
};

const Summary = ({ data }) => {
  const summary = processData(data);
  console.log(summary);

  if (!isAdmin()) return null;

  const dynamicCards = Object.values(applicationConfigs).map((config) => (
    <Card className="summary-card" key={config.uploaded_key}>
      <Statistic title={config.summaryName} value={summary[config.summaryName]} />
    </Card>
  ));

  return (
    <div className="summary" style={{ padding: 20 }}>
      <Typography.Title>
        Monthly Stats Chart <Tag>Beta</Tag>
      </Typography.Title>
      <Divider  orientation="left"> Monthly Applications stats</Divider>
      <ChartsSummary users={data} />

      <Typography.Title>
        Overall stats
      </Typography.Title>
      <Divider orientation="left">General Statistics</Divider>
      <div className="summary-section">
        <Card className="summary-card">
          <Statistic title="Total Applications" value={summary.totalApplications} />
        </Card>
        <Divider orientation="left">Applications</Divider>
        {dynamicCards}
      </div>
      <Divider orientation="left">Gender Statistics</Divider>
      <div className="summary-section">
        <Card className="summary-card">
          <Statistic title="Male Applications" value={summary.maleApplications} />
        </Card>
        <Card className="summary-card">
          <Statistic title="Female Applications" value={summary.femaleApplications} />
        </Card>
      </div>
      {/* <Divider orientation="left">SNAP Applications</Divider>
      <div className="summary-section">
        <Card className="summary-card">
          <Statistic title="SNAP Applications" value={summary.snapApplications} />
        </Card>
      </div> */}
      <Divider orientation="left">Benefit Selected</Divider>
      <div className="summary-section">
        {Object.entries(summary.instrumentType).map(([type, count]) => (
          <Card className="summary-card" key={type}>
            <Statistic title={`Benefit selected - ${type ?? "noname"}`} value={count} />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Summary;
